import { createRouter, createWebHashHistory } from 'vue-router'

function page(path, name, pageTitle) {
  return { path, name, meta: { pageTitle }, component() { return import(`../views/${name}.vue`) } }
}

function page2(path, name, pageTitle) {
  return { path, name, meta: { pageTitle }, component() { return import(`../views/news/${name}.vue`) } }
}

const routes = [
  page("/", "Home", "株式会社ジャンプソフト"),
  page("/company", "Company", "会社案内 | 株式会社ジャンプソフト"),
  page("/service", "Service", "事業案内 | 株式会社ジャンプソフト"),
  page("/info", "Info", "お知らせ | 株式会社ジャンプソフト"),
  page2("/news/20210426", "20210426", "お知らせ | 株式会社ジャンプソフト")
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
