import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/ja'
import 'dayjs/locale/ja'
import router from './router'

import './element-variables.scss'

createApp(App).use(ElementPlus, { locale }).use(router).mount('#app')
