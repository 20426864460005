<template>
  <div class="header-bar" :class="headerBarClass" ref="headerBar">
    <div class="company-name">株式会社ジャンプソフト</div>
    <el-menu :default-active="$route.path" mode="horizontal" router>
      <el-menu-item index="/">ホーム</el-menu-item>
      <el-menu-item index="/company">会社案内</el-menu-item>
      <el-menu-item index="/service">事業案内</el-menu-item>
      <el-menu-item index="/info">お知らせ</el-menu-item>
    </el-menu>
    <div class="contacts">
      <small>お気軽にお問い合わせください</small>
      <div>☎ <a href="tel:03-3527-3483">03-3527-3483</a></div>
      <div>✉ <a href="mailto:info@jumpsoft.jp">info@jumpsoft.jp</a></div>
    </div>
  </div>
  <main :style="`min-height: ${minContentHeight}px`">
    <router-view />
  </main>
  <el-backtop />
  <footer ref="footer">
    <div>Copyright © 株式会社ジャンプソフト All Rights Reserved.</div>
    <div>Powered by Vue.js &amp; Element Plus</div>
  </footer>
</template>

<style lang="sass">
body
  margin: 0px
  overflow-y: scroll

.header-bar
  position: sticky
  background-color: white
  top: 0px
  border-bottom: 1px solid #e6e6e6
  display: flex
  padding: 0px max(20px, calc(50vw - 600px))
  box-sizing: border-box
  user-select: none
  transition: box-shadow 1s
  z-index: 999
  .company-name
    line-height: 64px
    font-size: 24px
    font-weight: bold
    flex-grow: 1
  >ul
    align-self: flex-end
    margin-bottom: -1px
    li.el-menu-item
      height: 64px
  .contacts
    text-align: end
    flex-grow: 2
    margin: 4px
    font-size: 13px
    small
      color: #909399
    a
      color: #3078c1
      user-select: auto
  @media (orientation: portrait)
    display: block
    padding: 0px
    .company-name
      font-size: 18px
      line-height: 48px
      height: 36px
      text-align: center
      padding-left: 12px
    >ul
      width: fit-content
      margin: auto
      margin-top: 32px
      margin-bottom: -1px
      li.el-menu-item
        height: 48px
    .contacts
      text-align: center
      position: absolute
      top: 40px
      width: 100%
      small
        display: block
      >div
        display: inline-block
        margin-right: 24px

.float-bar
  box-shadow: 0px 1px 10px #e6e6e6

footer
  padding: 24px 0px
  background-color: #000099
  color: lightgray
  text-align: center
  font-size: 13px
</style>

<script>
export default {
  data() {
    return {
      headerBarClass: "",
      minContentHeight: 0
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler({ meta: { pageTitle } }) {
        document.title = pageTitle;
      },
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.headerBarClass = window.scrollY > 0 ? "float-bar" : ""
      if (this.$route.name == "Home") {
        document.querySelectorAll(".part-content").forEach(dom => {
          const { top, bottom } = dom.getBoundingClientRect()
          if (top > (window.innerHeight || document.documentElement.clientHeight) || bottom <= this.$refs.headerBar.clientHeight) {
            dom.classList.add("part-content-out")
          }
          else {
            dom.classList.remove("part-content-out")
          }
        })
      }
    });
    window.addEventListener("resize", () => this.fitWindowSize());
    this.$nextTick(this.fitWindowSize);
  },
  methods: {
    fitWindowSize() {
      this.minContentHeight = window.innerHeight - this.$refs.headerBar.clientHeight - this.$refs.footer.clientHeight - 1
    }
  },
};
</script>
